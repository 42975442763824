.view-students-container {
    padding: 20px;
    max-width: 1200px;
    margin: 0 auto;
}

.button-container-add {
    display: flex;
    gap: 10px; /* Add some spacing between the buttons */
    margin-top: 10px; /* Optional: Adjust spacing from other elements */
}

.controls {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.controls-row {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.grade-select {
    /* display: flex;
    align-items: center; */
    margin-right: 900px;
}

.grade-select label {
    margin-right: 10px;
    font-weight: bold;
}

.grade-select select {
    padding: 8px;
    border-radius: 4px;
    border: 1px solid #ccc;
}

.search-input {
    padding: 8px;
    border-radius: 4px;
    border: 1px solid #ccc;
    width: 100%;
}

.add-student-button {
    padding: 10px 20px;
    background-color: #8ac429;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: larger;
    font-family: cursive;
    align-self: flex-end; /* Aligns button to the right */
}

.add-student-button:hover {
    background-color: #ff615d;
}

.students-table table {
    width: 100%;
    border-collapse: collapse;
}

.students-table th,
.students-table td {
    padding: 10px;
    text-align: left;
    border: 1px solid #ddd;
}

.students-table th {
    background-color: #f2f2f2;
}

.students-table tr:nth-child(even) {
    background-color: #f9f9f9;
}

.students-table img {
    border-radius: 50%;
}
