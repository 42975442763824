/* Ensure the container centers its content */
.edit-comments-container {
    max-width: 900px;
    margin: 20px auto;
    padding: 20px;
    border: 1px solid #ddd;
    border-radius: 8px;
    background-color: #f9f9f9;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
    align-items: center; /* Center content horizontally */
    justify-content: center; /* Center content vertically */
}

/* Center personal details */
.personal-details {
    display: flex;
    flex-direction: column; /* Stack the details vertically */
    align-items: center;    /* Center the items horizontally */
    justify-content: center; /* Center the items vertically */
    text-align: center;     /* Align the text in the center */
    margin-bottom: 20px;    /* Add some spacing below the details */
}

/* Center the form */
.comments-form {
    display: flex;
    flex-direction: column;
    align-items: center; /* Center the form elements horizontally */
    justify-content: center; /* Center the form elements vertically */
    margin-top: 20px;
    width: 100%; /* Ensure the form doesn't exceed the container width */
    max-width: 700px; /* Limit the form width for better design */
}

textarea {
    width: 100%; /* Make textareas take the full width of the form */
    resize: none;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    margin-bottom: 20px;
    font-family: Arial, sans-serif;
    font-size: 14px;
}



h2 {
    text-align: center;
    color: #333;
}

strong {
    color: #555;
}


.comments-form {
    display: flex;
    flex-direction: column;
    margin-top: 20px;
}

label {
    font-weight: bold;
    margin-bottom: 10px;
}

textarea {
    resize: none;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    margin-bottom: 20px;
    font-family: Arial, sans-serif;
    font-size: 14px;
}


.save-button, .cancel-button {
    border: none;
    padding: 10px 15px;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s;
}

.save-button {
    background-color: #4CAF50;
    color: white;
}

.save-button:hover {
    background-color: #45a049;
}

.cancel-button2 {
    border: 5px solid #8ac429; /* Visible thick border with green color */
    color: #8ac429;           /* Green text color */
    background-color: white;  /* White background */
    padding: 7px 22px;       /* Add padding for better appearance */
    border-radius: 8px;       /* Optional: Add rounded corners */
    cursor: pointer;          /* Pointer cursor on hover */
    font-size: 17px;          /* Adjust font size */
    transition: background-color 0.3s, color 0.3s; /* Smooth transitions */
    font-family: cursive;
}

.cancel-button2:hover {
    background-color: #8ac429; /* Green background on hover */
    color: white;              /* White text color on hover */
}


.cancel-button:hover {
    background-color: #d32f2f;
}
.success-message5 {
    margin-top: 20px;
    padding: 10px;
    color: #155724;
    background-color: #d4edda;
    border: 1px solid #c3e6cb;
    border-radius: 4px;
    text-align: center;
}
