.view-behaviour-goals-container {
    padding: 20px;
    max-width: 1200px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.header {
    text-align: center;
    margin-bottom: 20px;
    font-size: 24px;
}

.controls {
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 20px;
}

.grade-select {
    flex: 1;
    display: flex;
    align-items: center;
    gap: 10px;
}

.add-behaviour-goal-button {
    padding: 10px 20px;
    background-color: #8ac429;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: larger;
    font-family: cursive;
    margin-left:850px ;
}

.add-behaviour-goal-button:hover {
    background-color: #ff615d;
}

.error-message {
    color: red;
    font-weight: bold;
    margin-top: 10px;
}
