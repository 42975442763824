/* General Styles */
body {
    margin: 0;
    font-family: Arial, sans-serif;
    line-height: 1.6;
    color: #333;
    background-color: #f9f9f9;
}

.landing-page {
    display: flex;
    flex-direction: column;
    align-items: center;
}

/* Navbar */
.navbar {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
   
    padding: 1rem 2rem;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    background: linear-gradient(to right, rgb(255, 97, 93), hsl(78, 51%, 58%));
 
}

.navbar-brand {
    font-size: 1.8rem; /* Slightly larger */
    font-weight: bold;
}
.navbar h1 {
    font-size: 3.5rem; /* Larger font size */
    font-weight: bold;
    color: white;
    font-style: italic; /* Italicized */
    margin: 0;
}

.navbar img {
    height: 60px; /* Adjust the height to make it smaller */
    width: 160px; /* Maintain aspect ratio */
}

/* Updated Login Button */
.login-button3 {
    background-color: #555e39; /* Bright orange for visibility */
    color: white;
    padding: 1rem 2rem; /* Larger padding for size */
    border: none;
    border-radius: 14px; /* More pronounced rounding */
    cursor: pointer;
    font-size: 1.5rem; /* Bigger font */
    margin-right: 40px;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1); /* Subtle shadow for emphasis */
    transition: transform 0.2s, background-color 0.2s;
}

.login-button3:hover {
    background-color: #e64a19; /* Slightly darker orange on hover */
    transform: scale(1.1); /* Slight zoom on hover */
}

/* Hero Section */
.hero {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 2rem;
    background-color: #f5f6e7; /* Light complementary shade */
    width: 100%;
    text-align: left;
}

.hero-image {
    max-width: 40%;
    height: auto;
    border-radius: 10px;
}

.hero-content {
    max-width: 50%;
    display: flex;
    flex-direction: column;
    justify-content: center; /* Vertically centers the content */
    align-items: center;    /* Horizontally centers the content */
    text-align: center;     /* Centers text alignment */
    margin: 0 auto;         /* Ensures horizontal centering */
}

.hero-content h1 {
    font-size: 2.5rem;
    color: #555e39; /* Muted olive green */
    margin-bottom: 1rem; /* Adds space between heading and paragraph */
}

.hero-content p {
    font-size: 1.5rem;
    margin: 1rem 0;
}

.cta-button {
    background-color: rgb(255, 97, 93);
   
    color: white;
    padding: 0.8rem 2rem;
    border: none;
    border-radius: 10px;
    cursor: pointer;
    font-size:  1.8rem; /* Increased font size */
}

.cta-button:hover {
    /* background-color: #218838; Darker green on hover */
  
    background-color: #28a745; /* Bright green for CTA */
}


/* Benefits Section */
.benefits {
    text-align: center;
    padding: 2rem;
    background-color: #fff;
    width: 100%;
}
.icon {
    font-size: 2.5rem; /* Adjust size as needed */
    color: #555e39; /* Match the theme */
    margin-bottom: 0.5rem;
  }
  
.benefits h2 {
    font-size: 2.5rem;
    color: #555e39;
    margin-bottom: 1rem;
}

.benefits-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    gap: 1.5rem;
}

.benefit {
    background-color:#e7f5e8 ; /* Light pastel green for a cohesive look */
    padding: 1.5rem;
    border-radius: 8px;
}

.benefit h3 {
    font-size: 1.5rem;
    color: #333;
}

.benefit p {
    font-size: 1.5rem;
    margin: 0.5rem 0;
}

/* Audience Section */
.audience {
    padding: 2.5rem;
    background-color: #f5f6e7; /* Same as hero for consistency */
    width: 100%;
    text-align: center; /* Center the content */
}

.audience h2 {
    font-size: 2.5rem;
    margin-bottom: 1rem;
    color: #555e39;
}

.audience ul {
    list-style: none;
   
}

.audience ul li {
    margin: 2rem 0;
    font-size: 1.7rem;
}

/* Features Section */
.features {
    text-align: center;
    padding: 2.5rem;
    background-color: #fff;
    width: 100%;
}

.features h2 {
    font-size: 2.5rem;
    color: #555e39;
    margin-bottom: 1rem;
}

.features-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    gap: 1.5rem;
}

.feature {
    background-color: #f5f6e7; /* Light complementary shade */ /* Light pastel green for a cohesive look */
    padding: 1.5rem;
    border-radius: 8px;
}

.feature h3 {
    font-size: 1.5rem;
    color: #333;
}

.feature p {
    font-size: 1.5rem;
    margin: 0.5rem 0;
}

/* Footer */
.footer {
    background: linear-gradient(to right, rgb(255, 97, 93), hsl(78, 51%, 58%));
    color: #fff;
    text-align: center;
    padding: 1rem;
    margin-top: 2rem;
    width: 100%;
}

.footer p {
    margin: 0;
    font-size: 0.9rem;
}
