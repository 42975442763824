/* SubjectFormativePerformance.css */

.container {
    text-align: center;
    max-width: 1200px;
    margin: 0 auto;
    padding: 20px;
    font-family: Arial, sans-serif;
    border: 2px solid #ddd;
    border-radius: 8px;
  }
  
  .heading {
    color: #333;
    margin-bottom: 60px;
  
  }
  
  .button-group {
    display: flex;
    justify-content: center;
    gap: 15px;
    margin-bottom: 20px;
  } 

/* SubjectFormativePerformance.css */

.form-group5 {
    display: flex;
    justify-content: center;
    margin: 20px 0;
}

.submit-button4 {
    color: black;
    background-color: #8ac429;
    max-width: 300px;
    padding: 12px 25px;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    font-size: 1.3em;
    font-family: cursive;
}


  
  .button {
    color: white;
    padding: 12px 25px;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    font-size: 16px;
  }
  
  .button-grade {
    background-color: #ffb134;
  }
  
  .button-selected {
    background-color: #8ac429;
  }
  
  .form-group {
    margin: 20px 0;
  }
  
  .label {
    display: block;
    margin-bottom: 10px;
    font-size: 16px;
  }
  
  .select {
    margin-left: 10px;
    padding: 8px;
    border-radius: 5px;
    border: 1px solid #ddd;
  }
  
  .submit-button {
    padding: 12px 25px;
    background-color: #8ac429;
    color: white;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    font-size: 16px;
    margin-top: 10px;
  }
  
  .table {
    margin-top: 20px;
    width: 100%;
    border-collapse: collapse;
  }
  
  .table th, .table td {
    border: 1px solid #ddd;
    padding: 10px;
    text-align: center;
  }
  
  .table thead {
    background-color: #f2f2f2;
  }
  
  .download-button {
    margin-top: 20px;
    background-color: #ff615d;
    color: white;
    border: none;
    padding: 12px 25px;
    cursor: pointer;
    border-radius: 8px;
    font-size: 16px;
  }
  