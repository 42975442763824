.reset-staff-password {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 50px;
    background-color: #f5f5f5;
    min-height: 100vh;
}

h2 {
    margin-bottom: 1.5em;
    font-size: 1.8em;
    color: #333;
    text-align: center;
}

.staff-info {
    background: #fff;
    padding: 2em;
    width: 100%;
    max-width: 800px;
    border-radius: 8px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    margin-bottom: 1.5em;
    font-size: 1em;
    line-height: 1.5;
}

p {
    margin: 0.5em 0;
}

.submit-button3 {
    margin-top: 1em;
    padding: 0.75em;
    border: none;
    background-color: #8ac429;
    font-size: 1.3em;
    border-radius: 4px;
    cursor: pointer;
    text-align: center;
    font-family: cursive;
    font-weight: 500;
}

.submit-button3:hover {
    background-color: #0056b3;
}

.success {
    background-color: #d4edda;
    color: #155724;
    padding: 0.75em;
    border-radius: 4px;
    text-align: center;
    font-size: 0.9em;
    margin-top: 1em;
}
