.report-container-formative5 {
    max-width: 1200px; /* Increase the size of the container */
    margin: 0 auto; /* Center the container */
    padding: 20px;
    background-color: #f9f9f9;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

/* Additional styling for table and radio buttons */
.table-row {
    display: flex;
    align-items: center;
    padding: 10px;
}




.download-report-button{
    margin-left: 1200px;
    background-color:#8ac429 ;

    font-weight: 600;
    font-size: 17px;
   
}
.download-report-button:hover{
   
    background-color:#ff615d ;

    
   
}
.print-button {
    margin-left: 1380px;
    padding: 10px 15px;
    background-color:rgb(31, 155, 31);
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    margin-bottom: 10px;
}

.print-button:hover {
    background-color: #0056b3;
}

.pagination {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
}

.pagination button {
    padding: 5px 10px;
    background-color: #f0f0f0;
    border: 1px solid #ccc;
    cursor: pointer;
    color: black;
    
}

.pagination button:disabled {
    background-color: #e0e0e0;
    cursor: not-allowed;
 
}

/* The rest of your existing styles */


.student-info {
    margin-bottom: 20px;
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 4px;
    background-color: #f9f9f9;
}

.student-info p {
    margin: 5px 0;
}

.student-info strong {
    font-weight: bold; /* Bold headings for student details */
}

label {
    margin-top: 20px;
    display: block;
    font-weight: bold; /* Bold label */
}



.ui.celled.padded.table th:nth-child(1),
.ui.celled.padded.table td:nth-child(1) {
    width: 15%; /* Reduce width of the Learning Outcome column */
}

.ui.celled.padded.table th:nth-child(2),
.ui.celled.padded.table td:nth-child(2) {
    width: 15%; /* Reduce width of the Learning Outcome column */
}

.ui.celled.padded.table th:nth-child(3),
.ui.celled.padded.table td:nth-child(3) {
    width: 25%; /* Reduce width of the Learning Outcome column */
}

.ui.celled.padded.table th:nth-child(4),
.ui.celled.padded.table td:nth-child(4) {
    width: 100%; /* Increase width of the Assessment Rubric column */
}


.next-button-2{
    background-color: #ff615d;
}


.rubric-cell {
    margin-left: 10px; /* Add space between name and button */
    flex-shrink: 0; /* Prevent radio button from shrinking */
}


.rubric-row {
    display: flex; /* Align items in a row */
    align-items: center; /* Vertically center the content */
    justify-content: space-between; /* Space between name and radio button */
    margin-bottom: 10px; /* Add some spacing between rows */
}

.rubric-name {
    flex: 5; /* Take up available space */
    text-align: left; /* Align name to the left */
}
