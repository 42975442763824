/* AdminViewStudentBehaviourReport.css */

.behaviour-report {
    max-width: 1200px;
    margin: 0 auto;
    padding: 20px;
    background-color: #f9f9f9;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    font-family: Arial, sans-serif;
}

.behaviour-report h2 {
    color: #333;
    text-align: center;
    font-size: 24px;
    margin-bottom: 20px;
}

.student-details {
    text-align: center;
    font-size: 16px;
    color: #666;
    margin-bottom: 20px;
}

.behavior-table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 20px;
}

.behavior-table th, .behavior-table td {
    padding: 12px;
    border: 1px solid #ddd;
    text-align: center;
    font-size: 16px;
}

.behavior-table th {
    
    background-color: rgb(230, 229, 229);
    text-transform: uppercase;
    text-align: center;
}

.goal-column {
    width: 40%; /* Reduce width of Behaviour Goal column */
}

.assessment-column {
    width: 60%; /* Increase width of Assessment column */
}

.behavior-table tr:nth-child(even) {
    background-color: #f2f2f2;
}

.teacher-comments {
    margin-top: 30px;
    padding: 15px;
    background-color: #e8f4ea;
    border-radius: 6px;
    font-size: 16px;
}

.teacher-comments h3 {
    color: #333;
    font-size: 20px;
    margin-bottom: 10px;
}

.teacher-comments p {
    color: #555;
}


.print-button {
    margin-left: 1380px;
    padding: 10px 15px;
    background-color:rgb(31, 155, 31);
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    margin-bottom: 10px;
}


.print-button:hover {
    background-color: #45a049; /* Darker shade on hover */
}
