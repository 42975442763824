/* GradeDetails.css */
.stream-filter {
    display: flex;
    align-items: center;
    gap: 5px;
    margin-bottom: 20px;
    text-align: left; /* Ensures alignment to the left */
}

.filter-label {
    font-weight: bold;
    
}

.search-input {
    width: 100%;
    max-width: 100%; /* Make search input full-width */
    margin-bottom: 20px;
}
.button-container {
    display: flex;
    justify-content: center;
    margin-bottom: 20px; /* Space below buttons */
}

.custom-button {
    margin-right: 8px;
    padding: 12px 24px; /* Increased button size */
    font-size: 16px; /* Increased font size */
}
.assign-buttons {
    display: flex;
    gap: 15px;
    flex-wrap: wrap;
    justify-content: center; /* Center-align the buttons */
    margin-top: 10px;
}
.left-align {
    text-align: left !important;
}

.assign-button {
    display: flex;
    align-items: center;
  
    padding: 8px 12px;
   
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 30px;
    font-weight: 600;
    transition: background-color 0.3s ease;
    font-family: cursive;
}
