.report-container4 {
    max-width: 1200px;
    margin: 0 auto;
    text-align: center;
    padding: 20px;
    
}

h1 {
    color: #333;
    font-size: 2em;
    margin-bottom: 10px;
}

.student-info p {
    font-size: 1.1em;
    margin: 5px 0;
}

.tabs-container {
    margin-top: 20px;
}

.tabs {
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
}

.tab {
    padding: 10px 20px;
    cursor: pointer;
   
    border: 1px solid #ddd;
    margin: 0 5px;
    border-radius: 5px;
}

.tab.active {
    
    color: black;
}

.tab-content {
    text-align: center;
    margin-top: 20px;
}

select {
    padding: 8px;
    margin-top: 10px;
    font-size: 1em;
}

.report-table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 20px;
}

.report-table th, .report-table td {
    padding: 10px;
    border: 1px solid #ddd;
    text-align: center;
}

.report-table th {
    background-color: #f8f8f8;
    font-weight: bold;
}

.report-table tr:nth-child(even) {
    background-color: #f2f2f2;
}

p {
    font-size: 1em;
    color: #777;
}
.teacher-comments {
    position: relative; /* Make it a positioned container for the button */
    padding: 20px;
    border: 1px solid #ddd;
    border-radius: 5px;
    margin-top: 20px;
    text-align: left; /* Aligns text content within the container */
}

.edit-button-container {
    position: absolute;
    top: 10px; /* Position near the top */
    right: 10px; /* Position near the right */
}

.edit-comments-button {
    background-color: #4CAF50;
    color: white;
    border: none;
    padding: 10px 15px;
    cursor: pointer;
    border-radius: 5px;
    display: flex;
    align-items: center;
}
