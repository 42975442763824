.report-edit-container {
    max-width: 1000px;
    margin: 0 auto;
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    background-color: #f9f9f9;
    margin-top: 30px;
}

h2 {
    text-align: center;
    margin-bottom: 20px;
}

.edit-form {
    display: flex;
    flex-direction: column;
}

.goal-item {
    margin-bottom: 15px;
}

.goal-label {
    font-weight: bold;
}

.goal-input {
    margin-top: 5px;
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
    width: 100%;
    box-sizing: border-box;
}

.submit-button {
    margin-top: 20px;
    padding: 10px;
    background-color: green;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 16px;
}

.submit-button:hover {
    background-color: #0056b3;
}

.loading-text, .error-text {
    text-align: center;
    color: #ff0000; /* Red color for errors */
}

.error-text {
    color: red;
}
