
.report-container {
    padding: 20px;
    font-family: Arial, sans-serif;
}

.print-button {
    margin-left: 1380px;
    padding: 10px 15px;
    background-color:rgb(31, 155, 31);
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    margin-bottom: 10px;
}

.print-button:hover {
    background-color: #0056b3;
}

.pagination {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
}

.pagination button {
    padding: 5px 10px;
    background-color: #f0f0f0;
    border: 1px solid #ccc;
    cursor: pointer;
    color:black;
}

.pagination button:disabled {
    background-color: #e0e0e0;
    cursor: not-allowed;
    
}

/* The rest of your existing styles */


.student-info {
    margin-bottom: 20px;
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 4px;
    background-color: #f9f9f9;
}

.student-info p {
    margin: 5px 0;
}

.student-info strong {
    font-weight: bold; /* Bold headings for student details */
}

label {
    margin-top: 20px;
    display: block;
    font-weight: bold; /* Bold label */
}

.ui.celled.padded.table {
    width: 100%; /* Make the table occupy full width */
    border-collapse: collapse;
    margin-top: 20px;
}


.ui.celled.padded.table th:nth-child(1),
.ui.celled.padded.table td:nth-child(1) {
    width: 15%; /* Reduce width of the Learning Outcome column */
}

.ui.celled.padded.table th:nth-child(2),
.ui.celled.padded.table td:nth-child(2) {
    width: 15%; /* Reduce width of the Learning Outcome column */
}

.ui.celled.padded.table th:nth-child(3),
.ui.celled.padded.table td:nth-child(3) {
    width: 25%; /* Reduce width of the Learning Outcome column */

}
.ui.celled.padded.table th:nth-child(4),
.ui.celled.padded.table td:nth-child(4) {
    width: 100%; /* Adjust width to control space for the radio buttons */
}

.ui.celled.padded.table th,
.ui.celled.padded.table td {
    padding: 10px;
    text-align: left; /* Align text to the left */
    border: 1px solid #ddd;
    vertical-align: top; /* Ensure all table cells align at the top */
}

.rubric-cell {
    display: flex;
    justify-content: flex-end; /* Ensure alignment to the right edge */
    width: 100%; /* Ensure the cell spans the entire width */
}

.radio-container {
    display: flex;
    align-items: center; /* Align radio button and label vertically */
    justify-content: flex-end; /* Align to the right */
    margin-bottom: 5px; /* Add spacing between radio button options */
    width: 100%;
}

.radio-container label {
    display: flex;
    justify-content: space-between;
    width: 100%;
}

input[type="radio"] {
    margin-left: 10px; /* Add space between the label and radio button */
    position: relative;
}

.rubric-label {
    margin-right: 10px; /* Ensure space between the label and the radio button */
    text-align: left;
    flex-grow: 1; /* Make the label take up available space */
}
