/* AssignClassTeacher.css */

.assign-class-teacher-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 20px;
    background-color: white;
    padding: 20px;
    width: 100%;
    max-width: 1000px;
    margin-left: auto;
    margin-right: auto;
    border: 1px solid #ccc; /* Border added to make container edges visible */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Optional shadow for depth */
    position: relative; /* Allows positioning of elements within container */
    border-radius: 8px;
}

.header {
    text-align: center;
    margin-bottom: 20px;
    color: #333;
    font-size: 24px;
}

.assign-class-teacher-form {
    width: 100%;
    max-width: 800px;
    border: 1px solid #ddd;
    border-radius: 8px;
    padding: 20px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
}

.form-group {
    display: flex;
    flex-direction: column;
}

.form-group label {
    margin-bottom: 8px;
    font-weight: bold;
    color: #555;
}

.form-group select {
    padding: 8px 12px;
    font-size: 16px;
    border: 1px solid #ccc;
    border-radius: 4px;
    background-color: #fff;
    transition: border-color 0.3s ease;
}

.form-group select:focus {
    border-color: #4CAF50;
    outline: none;
}




.error-message {
    color: #f44336;
    font-weight: bold;
    margin-top: -10px;
    margin-bottom: 10px;
}

.success-message {
    color: #4CAF50;
    font-weight: bold;
    margin-bottom: 10px;
}

.spinner {
    animation: spin 1s linear infinite;
}

@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}

/* Responsive Design */

@media (max-width: 600px) {
    .assign-class-teacher-container {
        padding: 15px;
    }

    .header {
        font-size: 20px;
    }

    .form-group select {
        font-size: 14px;
        padding: 6px 10px;
    }

    .assign-button {
        font-size: 14px;
        padding: 8px 12px;
    }
}
