.view-teachers-container {
    padding: 20px;
    max-width: 1200px;
    margin: 0 auto;
    background-color: #f5f5fb;
}

.header {
    text-align: center;
    margin-bottom: 20px;
}

.controls2 {
    display: flex;
    flex-direction: column;
    align-items: center; /* Center-align all items in controls */
    gap: 15px;
    margin-bottom: 20px;
}
.search-bar{
    margin-bottom: 30px;

}


.grade-select {
    display: flex;
    align-items: center;
}

.grade-select label {
    margin-right: 10px;
    font-weight: bold;
}

.grade-select select {
    padding: 5px 10px;
    font-size: 16px;
}

.assign-buttons2 {
    display: flex;
    gap: 10px;
    flex-wrap: wrap;
    justify-content: center; /* Center-align the buttons */
    margin-top: 10px;
}
.assign-button2 {
    display: flex;
    align-items: center;
    gap: 5px;
    padding: 8px 12px;
   
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 18px;
    font-weight: 600;
    transition: background-color 0.3s ease;
    font-family: cursive;
}

.assign-button2:hover {
  color: black;
}

.assign-button2.active-tab {
    color:black;
    
}


.teachers-table {
    overflow-x: auto;
}

.ui.celled.table {
    width: 100%;
    border-collapse: collapse;
}

.ui.celled.table th, .ui.celled.table td {
    border: 1px solid #ddd;
    padding: 8px;
    text-align: center;
}

.ui.celled.table th {
    background-color: #f2f2f2;
    font-weight: bold;
}

.action-icons {
    display: flex;
    justify-content: center;
    gap: 10px;
}

.edit-icon, .delete-icon {
    cursor: pointer;
    font-size: 18px;
}

.edit-icon {
    color: #4CAF50;
}

.delete-icon {
    color: #f44336;
}

.error-message {
    color: red;
    font-weight: bold;
}
