.change-password {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 50px; /* Adds space from the top of the screen */
    background-color: #f5f5f5;
    min-height: 100vh;
}

h2 {
    margin-bottom: 1.5em;
    font-size: 1.8em;
    color: #333;
    text-align: center;
}

form {
    background: #fff;
    padding: 2em;
    width: 100%;
    max-width: 700px;
    border-radius: 8px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
    gap: 1em;
}

  .input-group {
    display: flex;
    flex-direction: column;
  }

  label {
    margin-bottom: 0.5em;
    font-weight: bold;
  }

  .password-input-container {
    display: flex;
    align-items: center;
    position: relative;
  }

  input {
    flex: 1;
    padding: 0.75em;
    padding-right: 2.5em;
    border: 1px solid #ddd;
    border-radius: 4px;
  }

  .eye-icon {
    background: none;
    border: none;
    position: absolute;
    right: 0.5em;
    cursor: pointer;
    color: #555;
  }

  .submit-button3 {
    margin-top: 1em;
    padding: 0.75em;
    border: none;
    background-color: #8ac429;    
    font-size: 1.3em;
    border-radius: 4px;
    cursor: pointer;
    text-align: center;
    align-self: center;
    font-family: cursive;
    font-weight: 500;
  }

  .submit-button3:hover {
    background-color: #0056b3;
  }

  .error, .success {
    text-align: center;
    font-size: 0.9em;
    padding: 0.75em;
    border-radius: 4px;
  }

  .error {
    background-color: #f8d7da;
    color: #721c24;
  }

  .success {
    background-color: #d4edda;
    color: #155724;
  }