.view-staff-container {
    padding: 20px;
    background-color: white;
}

/* Add a flex container for the search input and button */
.search-and-add-container {
    display: flex;
    flex-direction: column; /* Arrange items in a column */
    align-items: flex-start; /* Align items to the left by default */
    gap: 10px; /* Add spacing between elements */
    margin-bottom: 20px;
    position: relative; /* Allows positioning the button */
}

.add-staff-button {
    align-self: flex-end; /* Align the button to the right */
    padding: 10px 20px;
    background-color: #8ac429;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: larger;
    font-family: cursive;
}

.add-staff-button:hover {
    background-color: #ff615d;
}

/* Optional: Add some margin for the search input */
.search-input {
    margin-top: 10px;
    margin-bottom: 20px;
}


/* In your CSS file, e.g., ViewStaff.css */
.header {
    font-size: 2em;
    color: #ff615d;
    text-align: center;
    padding: 10px 0;
    margin-bottom: 20px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
    background-color: #fff;
    border-radius: 5px;
}

.staff-table {
    width: 100%;
    margin-bottom: 20px;
    border-collapse: collapse; /* Collapse borders for better appearance */
}

.staff-table th, .staff-table td {
    padding: 10px; /* Add padding for table cells */
    text-align: left; /* Left-align text in cells */
}


@media (max-width: 768px) {
    .staff-table th, .staff-table td {
        display: block; /* Stack table cells */
        width: 100%; /* Full width for each cell */
    }

    .action-buttons {
        display: flex;
        flex-direction: column; /* Stack action buttons vertically */
    }

    .add-staff-button {
        float: none; /* Reset float on smaller screens */
        width: 100%; /* Full width on mobile */
    }
}
