.report-container {
    padding: 20px;
    max-width: 800px;
    margin: 0 auto;
    font-family: Arial, sans-serif;
}

.report-title {
    text-align: center;
    font-size: 24px;
    font-weight: bold;
    color: #333;
    margin-bottom: 20px;
}

.button-container {
    display: flex;
    justify-content: center;
    gap: 10px;
    margin-bottom: 20px;
}

.view-button {
    padding: 10px 15px;
    font-size: 16px;
    border: none;
    cursor: pointer;
    border-radius: 5px;
    transition: background-color 0.3s;
}

.view-button.active, .view-button:hover {
    background-color: #007BFF;
    color: #fff;
}

.dropdown-container {
    display: flex;
    justify-content: center;
    gap: 10px;
    margin-bottom: 20px;
}

.dropdown {
    padding: 8px;
    font-size: 16px;
    border-radius: 5px;
}

.fetch-button {
    padding: 10px 15px;
    background-color: #28a745;
    color: white;
    border: none;
    cursor: pointer;
    border-radius: 5px;
    transition: background-color 0.3s;
}

.fetch-button:hover {
    background-color: #218838;
}

.results-container {
    margin-top: 20px;
    text-align: center;
}

.results-title {
    font-size: 20px;
    color: #333;
    margin-bottom: 10px;
}

.grade-info, .stream-info {
    font-size: 18px;
    color: #555;
    margin-bottom: 10px;
}

.performance-table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 10px;
}

.performance-table th, .performance-table td {
    padding: 10px;
    border: 1px solid #ddd;
    text-align: center;
}

.performance-table th {
    background-color: #f8f9fa;
    font-weight: bold;
}

.performance-table tr:nth-child(even) {
    background-color: #f2f2f2;
}
/* Adjusted the existing styles to separate the fetch button */
.fetch-container {
    display: flex;
    justify-content: center;
    margin-top: 20px;
    margin-bottom: 20px;
}

.download-button {
    padding: 10px 15px;
    background-color: #17a2b8;
    color: white;
    border: none;
    cursor: pointer;
    border-radius: 5px;
    transition: background-color 0.3s;
    margin-top: 10px;
}

.download-button:hover {
    background-color: #138496;
}

