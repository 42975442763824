.view-streams-container {
    padding: 20px;
    max-width: 1200px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.header {
    text-align: center;
    margin-bottom: 20px;
    font-size: 24px;
}

.controls {
    display: flex;
    flex-direction: column;
    gap: 10px;
    align-items: center; /* Center the controls, including the button */
}

.grade-select2 {
    margin-right: 770px;
}

.grade-select2 label {
    margin-right: 10px;
    font-weight: bold;
}

.grade-select2 select {
    padding: 8px;
    border-radius: 4px;
    border: 1px solid #ccc;
}

.add-stream-button {
    padding: 10px 20px;
    background-color: #8ac429;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: larger;
    font-family: cursive;
}

.add-stream-button:hover {
    background-color: #ff615d;
}

.streams-table {
    width: 80%;
    display: flex;
    justify-content: center;
    margin-top: 20px;
}

.streams-table table {
    width: 100%;
    border-collapse: collapse;
}

.streams-table th,
.streams-table td {
    padding: 12px;
    text-align: center;
    border: 1px solid #ddd;
}

.streams-table th {
    background-color: #f2f2f2;
}

.streams-table tr:nth-child(even) {
    background-color: #f9f9f9;
}

.error-message {
    color: red;
    font-weight: bold;
    margin-top: 10px;
}

.action-buttons button {
    display: flex;
    align-items: center;
    gap: 5px;
}

.action-buttons .icon {
    font-size: 16px;
}
