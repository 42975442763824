
.add-behaviour-goals-container {
    padding: 20px;
    max-width: 900px;
    margin: auto;
    border: 1px solid #ccc;
    border-radius: 5px;
    background-color: #f9f9f9;
}

.header {
    text-align: center;
}

.grade-select-goal {
    margin-bottom: 20px;
}

.grade-select-goal select {
    padding: 10px;
    font-size: 16px;
    width: 100%;
    max-width: 400px;
    border: 1px solid #ccc;
    border-radius: 4px;
}

.goal-input-goal {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
}

.goal-input-goal input {
    flex-grow: 1;
    padding: 8px;
    margin-right: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
}

.remove-goal-button {
    background: none;
    border: none;
    cursor: pointer;
    color: red;
    font-size: 20px;
}

.button-container-goal {
    display: flex;
    justify-content: center;
    gap: 10px;
    margin-top: 20px;
}


.submit-goals-button {
    padding: 10px 20px;
    font-size: 20px;
    cursor: pointer;
    background-color: #ff615d;
    font-family: cursive;


}
.submit-goals-button:hover {
   color: white;
}
.add-goal-button:hover {
    color: white;
 }
 

.add-goal-button
{
    padding: 10px 20px;
    font-size: 20px;
    cursor: pointer;
    background-color: #8ac429;

   
    font-family: cursive;

}


