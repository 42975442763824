/* ViewGrades.css */

.grid-container {
    display: flex;
    justify-content: center; /* Center horizontally */
    align-items: flex-start; /* Align items to the top */
    min-height: 100vh; /* Full viewport height for vertical centering */
    padding: 20px; /* Optional: Add padding for better spacing on smaller screens */
}

.view-grades-container {
    width: 100%; /* Set a width for the container */
    max-width: 1400px; /* Optional max width */
    padding: 20px; /* Optional padding */
    border: 1px solid #ccc; /* Optional border for visibility */
    border-radius: 8px; /* Optional rounded corners */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Optional shadow for depth */
    background-color: #fff; /* Optional background color */
}

.header {
    margin-bottom: 20px;
    color: #333;
}

.controls {
    display: flex;
    justify-content: flex-end; /* Align the add button to the right */
    margin-bottom: 20px;
}

.categories-container {
    display: flex;
    flex-direction: column;
    gap: 40px; /* Space between categories */
    align-items: center; /* Center the categories horizontally */
   
}

.category-section {
    /* Optional: Add a border or background to categories */
    width: 100%; /* Ensure category sections take full width */
    max-width: 1200px; /* Optional max width for categories */
    
}

.category-section h3 {
    margin-bottom: 20px;
    color: #555;
    text-align: center; /* Center the category headers */
    
}

.grade-card {
    width: 100%; /* Occupy full width of the grid cell */
    margin: 0; /* Remove external margins to let grid gap handle spacing */
    position: relative; /* To position header absolutely inside */
    padding: 15px; /* Optional: Add padding inside the card */
    box-sizing: border-box; /* Include padding and border in the element's total width and height */
    border: 1px solid #ddd; /* Optional: Add a subtle border */
    border-radius: 8px; /* Rounded corners */
    background-color: #fafafa; /* Light background for better contrast */
    transition: transform 0.2s, box-shadow 0.2s; /* Smooth transition for hover effect */
    height: 250px; /* Fixed height; adjust as needed */
   
}

.grade-card:hover {
    transform: translateY(-5px); /* Slight lift on hover */
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2); /* Enhanced shadow on hover */
}


.card-content-wrapper {
    position: relative; /* Establishes a positioning context */
}

.grade-header {
    position: absolute;
    top: 50%; /* Center vertically over the image */
    left: 50%; /* Center horizontally */
    transform: translate(-50%, -50%); /* Offset to truly center */
    background-color: rgba(255, 255, 255, 0.7); /* Semi-transparent background */
    padding: 15px 10px;
    border-radius: 4px;
    text-align: center;
    font-size: 1.2em; /* Adjust font size as needed */
}

.grade-image {
    width: 80%; /* Set image to 80% of the card's width */
    height: auto; /* Maintain the aspect ratio */
    object-fit: cover; /* Ensure the image covers the area without distortion */
    border-radius: 4px; /* Optional: Rounded corners for the image */
    transition: transform 0.2s, box-shadow 0.2s; /* Smooth transition for hover effect */
    display: block; /* Ensures proper centering */
    margin-left:  23px; /* Center the image horizontally and add vertical spacing */
    margin-top: 30px;
}

.grade-image:hover {
    transform: scale(1.05); /* Slight zoom on hover */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Add shadow on hover */
}

/* Adjust the Card.Group spacing */
.ui.cards {
    display: flex;
    flex-wrap: wrap;
    justify-content: center; /* Center the cards horizontally */
    gap: 20px; /* Space between cards */
}

/* Responsive Design Enhancements */

@media (max-width: 768px) {
    .controls {
        justify-content: center;
    }

    .categories-container {
        gap: 30px;
    }

    .grade-image {
        width: 80%; /* Maintain image size */
        height: auto; /* Ensure responsiveness */
    }

    .category-section h3 {
        text-align: center; /* Ensure headers are centered on smaller screens */
    }

    .grade-card {
        max-width: 100%; /* Allow the card to take full width on smaller screens */
        margin: 10px 0; /* Reduce margin for smaller screens */
    }

    .grade-header {
        font-size: 1em; /* Adjust font size for smaller screens */
        padding: 3px 6px;
    }
}
