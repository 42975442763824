.formative-report-container {
    max-width: 1000px;
    margin: auto;
    padding: 20px;
    background: #f9f9f9;
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  }
  
  h2 {
    text-align: center;
    color: #333;
  }
  
  .form-group {
    margin-bottom: 15px;
  }
  
  .label {
    display: block;
    margin-bottom: 5px;
    font-weight: bold;
  }
  
  select {
    width: 100%;
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  
  .error-message {
    color: red;
    text-align: center;
  }
  
  .student-checkbox {
    margin: 5px 0;
  }
  
  .generate-report-button {
    width: 100%;
    padding: 10px;
    background-color: #28a745;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 16px;
  }
  
  .generate-report-button:hover {
    background-color: #218838;
  }
  .tabs {
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
}

.tabs button {
    background-color: #f0f0f0; /* Default background */
    border: 1px solid #ccc;
    border-radius: 4px;
    padding: 10px 20px;
    cursor: pointer;
    margin: 0 5px; /* Add some spacing between buttons */
    transition: background-color 0.3s, color 0.3s; /* Smooth transition for hover and active states */
}

.tabs button:hover {
    background-color: #e0e0e0; /* Lighter background on hover */
    color: black;

}

.tabs button.active {
    background-color: #ffb134; /* Green background for active button */
   font-family: cursive;
   font-size: 17px;
    border: 1px solid #ffb134; /* Matching border color for active button */
    color: white;
}
