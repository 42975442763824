.container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 20px;
    background-color: #f9f9f9;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  .header {
    text-align: center;
    color: #333;
    margin-bottom: 20px;
  }
  
  .select-group {
    margin-bottom: 15px;
  }
  
  .student-search h3 {
    margin-top: 20px;
    color: #333;
  }
  
  .student-search input {
    padding: 8px;
    margin-left: 10px;
    border-radius: 5px;
    border: 1px solid #ddd;
  }
  
  .student-table table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 15px;
  }
  
  .student-table th,
  .student-table td {
    padding: 12px;
    border: 1px solid #ddd;
    text-align: center;
  }
  
  .student-table th {
    background-color: dadada;

  }
  
  .actions button {
    margin: 5px;
    padding: 8px 12px;
    border: none;
    background-color: #4CAF50;
    color: white;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .actions button:hover {
    background-color: #45a049;
  }
  