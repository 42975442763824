/* EditStream.css */

.edit-stream-container {
    max-width: 900px; /* Limit the width of the container */
    margin: 20px auto; /* Center the container on the page */
    padding: 20px;
    border: 1px solid #ddd; /* Add a light border */
    border-radius: 8px; /* Rounded corners */
    background-color: #f9f9f9; /* Light background color */
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1); /* Subtle shadow for depth */
}

h2 {
    text-align: center; /* Center the heading */
    color: #333; /* Darker color for the heading */
}

.form-group {
    margin-bottom: 15px; /* Space between form groups */
}

label {
    display: block; /* Make label a block element */
    margin-bottom: 5px; /* Space between label and input */
    font-weight: bold; /* Bold label text */
}

input[type="text"] {
    width: 100%; /* Full width input */
    padding: 10px; /* Space inside the input */
    border: 1px solid #ccc; /* Light border */
    border-radius: 4px; /* Rounded corners */
    font-size: 16px; /* Larger font for better readability */
}

input[type="text"]:focus {
    border-color: #4a90e2; /* Change border color on focus */
    outline: none; /* Remove default outline */
    box-shadow: 0 0 5px rgba(74, 144, 226, 0.5); /* Add shadow effect on focus */
}

.button-group {
    display: flex;
    justify-content: center; /* Centers the buttons horizontally */
    gap: 10px; /* Space between the buttons */
    margin-top: 20px; /* Add some space above the button group */
}

button {
    padding: 10px; /* Space inside the button */
    border: none; /* Remove border */
    border-radius: 4px; /* Rounded corners */
    font-size: 16px; /* Larger font for buttons */
    cursor: pointer; /* Pointer cursor for buttons */
    transition: background-color 0.3s; /* Smooth transition for background color */
}


button.ui.button {
    background-color: #4a90e2; /* Primary button color */
    color: white; /* Text color for primary button */
}

button.ui.button:hover {
    background-color: #ffb134; /* Darker shade on hover */
}

button.cancel {
    background-color: #f44336; /* Red color for cancel button */
    color: white; /* Text color for cancel button */
}

button.cancel:hover {
    background-color: #d32f2f; /* Darker shade on hover */
}

.error-message {
    color: #d32f2f; /* Red color for error messages */
    text-align: center; /* Center the error message */
    margin-top: 10px; /* Space above the error message */
}
.submit-button-special{
    padding: 10px 20px;
    font-size: 20px;
    cursor: pointer;
    background-color: #8ac429;
    font-family: cursive;
    


}
.cancel-button-special{
    padding: 10px 20px;
    font-size: 20px;
    cursor: pointer;
    background-color: #ff615d;
    font-family: cursive;
  
}

