.content-wrapper6 {
    padding: 40px;
    background-color: #f7f7f7;
    border-radius: 12px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    max-width: 1400px;
    margin: 0 auto;
}

.header {
    font-size: 28px;
    color: #333;
    margin-bottom: 30px;
    text-align: center;
}

.form-container6 {
    display: flex;
    flex-direction: column;
    gap: 20px;
    width: 100%;
    max-width: 1200px;
    margin: 0 auto;
    background-color: #ffffff;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
}

.select-container {
    display: flex;
    align-items: center;
    width: 100%;
    gap: 15px;
    margin-bottom: 20px;
  
}

.select-label {
    font-size: 16px;
    font-weight: bold;
    color: #555;
    min-width: 150px;

  
}

.select-input {
    padding: 10px;
    border-radius: 6px;
    border: 1px solid #ddd;
    flex: 1;
    background-color: #f0f0f0;
    transition: background-color 0.3s ease;
}

.select-input:focus {
    background-color: #e0e0e0;
    outline: none;
}

.ui.button {
    margin: 10px 0;
    padding: 10px 20px;
    border-radius: 6px;
    background-color: #4CAF50;
    color: #fff;
    border: none;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.ui.button:hover {
    background-color: #45A049;
}

.ui.button.red {
    background-color: #f44336;
}

.ui.button.red:hover {
    background-color: #d32f2f;
}

.ui.button.green {
    background-color: #4CAF50;
}

.ui.button.green:hover {
    background-color: #388E3C;
}

.error-message {
    color: #d32f2f;
    font-weight: bold;
    margin: 10px 0;
    text-align: center;
}

.learning-outcomes-list, .rubrics-list {
    list-style-type: none;
    padding: 0;
    margin-top: 10px;
}

.learning-outcome-item, .rubric-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #fff;
    padding: 12px;
    margin: 5px 0;
    border: 1px solid #ddd;
    border-radius: 6px;
    transition: box-shadow 0.2s ease;
}

.learning-outcome-item:hover, .rubric-item:hover {
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}


.substrand-container {
    margin-top: 10px; /* Add some space between the strand and substrand sections */
    display: flex;
    flex-direction: column;
}

.strand-section, .substrand-section, .learning-outcome-section, .assessment-rubrics-section {
    border: 1px solid #ccc;
    padding: 15px;
    margin-bottom: 20px;
    border-radius: 5px;
    background-color: #f9f9f9;
  }
  
  .strand-section h4, .substrand-section h4, .learning-outcome-section h4, .assessment-rubrics-section h4 {
    margin-bottom: 10px;
  }
  