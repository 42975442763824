/* AssignTeacherSubject.css */

.assign-subject-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 20px;
    background-color: white;
    padding: 20px;
    width: 100%;
    max-width: 1000px;
    margin-left: auto;
    margin-right: auto;
    border: 1px solid #ccc; /* Border added to make container edges visible */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Optional shadow for depth */
    position: relative; /* Allows positioning of elements within container */
    border-radius: 8px;
}
.back-button3 {
    background-color: #ff615d;
    color: white;
    border: none;
    padding: 10px 16px;
    font-size: 15px;
    border-radius: 4px;
    cursor: pointer;
    position: absolute;
    top: 10px;
    left: 10px; /* Position back button in the top-left corner */
    display: flex;
    align-items: center;
}

.header {
    text-align: center;
    margin-bottom: 20px;
    color: #333;
}

.assign-subject-form {
    width: 100%;
    max-width: 600px;
    border: 1px solid #ddd;
    border-radius: 8px;
    padding: 20px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
}

.form-group {
    display: flex;
    flex-direction: column;
}

.form-group label {
    margin-bottom: 8px;
    font-weight: bold;
    color: #555;
}

.form-group select {
    padding: 8px 12px;
    font-size: 16px;
    border: 1px solid #ccc;
    border-radius: 4px;
}



.error-message {
    color: #f44336;
    font-weight: bold;
}

.success-message {
    color: #4CAF50;
    font-weight: bold;
}

.spinner {
    animation: spin 1s linear infinite;
}

@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}

/* Styles for Dropdowns and Checkboxes */

.checkbox-group {
    display: flex;
    flex-direction: column; /* Arrange checkboxes vertically */
    gap: 10px; /* Space between each checkbox item */
}

.checkbox-item {
    display: flex;
    align-items: center; /* Vertically center the checkbox and label */
}

.checkbox-item input[type="checkbox"] {
    margin-right: 10px; /* Space between checkbox and label */
    width: 18px;
    height: 18px;
    cursor: pointer;
}

.checkbox-item label {
    cursor: pointer;
    font-size: 16px;
    color: #333;
}

/* Responsive Design */

@media (max-width: 600px) {
    .assign-subject-container {
        padding: 15px;
    }

    .assign-button {
        font-size: 14px;
        padding: 8px 12px;
    }

    .form-group select {
        font-size: 14px;
        padding: 6px 10px;
    }

    .checkbox-item label {
        font-size: 14px;
    }
}
