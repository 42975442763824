/* Container and header styling */

.App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
  }
  
  .App-link {
    color: #61dafb;
  }
  
  @keyframes App-logo-spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
  .assign-grade-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 20px;
    background-color: white;
    padding: 20px;
    width: 100%;
    max-width: 1000px;
    margin-left: auto;
    margin-right: auto;
    border: 1px solid #ccc; /* Border added to make container edges visible */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Optional shadow for depth */
    position: relative; /* Allows positioning of elements within container */
    border-radius: 8px;
}



.header {
    text-align: center;
    margin-bottom: 20px;
    color: #333;
    margin-top: 30px; /* Adds space between header and button */
}


/* Form styling */
.assign-grade-form {
    width: 100%;
    max-width: 600px;
    border: 1px solid #ddd;
    border-radius: 8px;
    padding: 20px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
}

.form-group {
    display: flex;
    flex-direction: column;
}

.form-group label {
    margin-bottom: 10px;
    font-weight: bold;
    color: #555;
}
/* Single-column layout for grades checkboxes */
.checkbox-group {
    display: flex;
    flex-direction: column; /* Stack items vertically */
    gap: 10px; /* Space between items */
    justify-content: start; /* Align items to the top */
    align-items: start; /* Align items to the left */
}

/* Style for each checkbox item */
.checkbox-item {
    display: flex;
    align-items: center;
    justify-content: start; /* Ensures left alignment */
    padding: 5px 0; /* Adds spacing for better readability */
}

/* Styling for the individual checkboxes */
.checkbox-item input[type="checkbox"] {
    margin-right: 10px; /* Space between checkbox and label */
    width: 18px;
    height: 18px;
    cursor: pointer;
}

/* Label styling */
.checkbox-item label {
    cursor: pointer;
    font-size: 16px;
    color: #333;
}


/* Center the submit button */
.assign-button3 {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
    padding: 10px 15px;
    background-color: #8ac429;  
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 20px;
    transition: background-color 0.3s ease;
    width: 50%; /* Width for centering */
    max-width: 200px;
    margin: 0 auto; /* Center-align button */
    font-family: cursive;
}

.assign-button3:hover {
    background-color: #45a049;
}

.assign-button3:disabled {
    background-color: #a5d6a7;
    cursor: not-allowed;
}

/* Error and success messages styling */
.error-message {
    color: #f44336;
    font-weight: bold;
}

.success-message {
    color: #4CAF50;
    font-weight: bold;
}

/* Spinner animation */
.spinner {
    animation: spin 1s linear infinite;
}

@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}
