.App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
  }
  
  .App-link {
    color: #61dafb;
  }
  
  @keyframes App-logo-spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
  .content-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 20px;
    background-color: white;
    padding: 20px;
    width: 100%;
    max-width: 1000px;
    margin-left: auto;
    margin-right: auto;
}

.top-navigation {
    display: flex;
    justify-content: flex-start;
    width: 100%;
    margin-bottom: 10px;
}

.back-button {
  background-color: #ff615d;
  color: white;
  border: none;
  padding: 16px 24px; /* Increase padding for a larger button */
  font-size: 18px; /* Increase font size */
  border-radius: 4px;
  cursor: pointer;
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.back-button:hover {
  background-color: #e04c48; /* Change to your desired hover color */
}
  
  .header {
    text-align: center;
    margin-bottom: 20px;
  }
  
  .form-container {
    width: 100%;
    max-width: 600px;
    border: 1px solid #ddd;
    border-radius: 8px;
    padding: 20px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  }
  
  .field {
    margin-bottom: 15px;
  }
  
  .field label {
    display: block;
    margin-bottom: 5px;
  }
  
  .field input,
  .field select {
    width: 100%;
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  
  .submit-button {
    display: flex;
    justify-content: center;
    margin-top: 20px;

  }
  
  .ui.negative.message,
  .ui.positive.message {
    margin-bottom: 20px;
  }
  
  .error-message {
    color: red;
    margin-top: 5px;
  }
  .submit{
    padding: 10px 20px;
    font-size: 1.3em;
    cursor: pointer;
    background-color: #8ac429;

   
    font-family: cursive;

  }
 