.performance-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 20px;
    padding: 20px;
    background-color: #f9f9f9;
    border-radius: 10px;
    max-width: 1000px;
    margin: 0 auto;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  .no-student {
    text-align: center;
    font-size: 18px;
    color: #333;
    margin-top: 50px;
  }
  
  h3 {
    text-align: center;
    color: #333;
  }
  
  .student-details p {
    margin: 5px 0;
    font-size: 16px;
  }
  
  .performance-table {
    width: 100%;
    margin-top: 20px;
    border-collapse: collapse;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  .performance-table th,
  .performance-table td {
    padding: 12px;
    text-align: center;
    border-bottom: 1px solid #ddd;
  }
  
  .performance-table th {
    background-color: #d1d2d1;
    color: black;
  }
  
  .performance-table td {
    font-size: 16px;
  }
  
  .performance-table tr:hover {
    background-color: #f1f1f1;
  }
  