/* DashboardLayout.css */

.dashboard-layout {
  display: flex;
  flex-direction: column;
  height: 100vh;
  background-color:white;
}

.header {
  background-color: white;
  padding: 0.2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.header-content {
  display: flex;
  align-items: center;
  justify-content: space-between; /* Ensures space between logo and title */
  width: 100%; /* Ensures the content stretches across the header */
  margin-top: -15px;
}

.logo {
  width: 90px;
  height: 60px;
  margin-left: 10px;
}

.dashboard-body {
  display: flex;
  flex: 1;
  background-color: #f0f0f0; /* Gray space between sidebar and header */
  padding: 20px;
}

/* Sidebar Styles */
.sidebar {
  width: 340px;
  background-color: white;
  color: #333;
  padding: 20px;
  border-radius: 10px; /* Curved border */
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  margin-right: 20px; /* Space between sidebar and dashboard content */
  height: 100%; /* Set the sidebar to take the full height of the container */
  overflow-y: auto; /* Enable vertical scrolling */
}

.profile-card {
  margin-bottom: 20px;
  text-align: center;
  width:100px;
  margin-left: -30px; /* Adjust the value to move the card to the left */
  padding-right: 20px; /* Optional: If you want to add some padding on the right for a balanced look */
}


.profile-picture img {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  margin-bottom: 10px;
}
/* Sidebar Menu Styles */
.sidebar-menu {
  display: flex;
  flex-direction: column; /* Stack menu items vertically */
  padding: 0;
  list-style: none;
  flex-grow: 1; /* Allow the sidebar menu to grow and fill space */
  justify-content: space-between; /* Distribute items evenly */
}

.sidebar-menu li {
  width: 100%; /* Ensure each menu item takes the full width */
  margin-bottom: 10px; /* Adjust this value to increase spacing */
  border-bottom: 1px solid rgba(0, 0, 0, 0.1); /* Faint line below each menu item */
  padding-bottom: 10px; /* Optional: Add padding below the text for better spacing */
}

.sidebar-menu li:last-child {
  border-bottom: none; /* Remove border for the last item */
}

.sidebar-menu li button {
  background: none;
  border: none;
  color: #333;
  font-size: 17px;
  cursor: pointer;
  padding: 15px;
  text-align: left; /* Align text to the left */
  width: 100%; /* Ensure buttons take full width */
  height: 100%;
  display: flex;
  align-items: center;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.sidebar-menu li button:hover {
  transform: translateY(-10px); /* Move the button upwards more */
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.4); /* Stronger shadow to create a more pronounced "pop" effect */
}
.active-menu {
  background-color: #731818;  /* Active color */
  color: #000;  /* Active text color */
}



/* Add margin-right to the icons */
.menu-icon {
  margin-right: 10px; /* Adjust the space between the icon and the text */
}

.dashboard-content {
  flex: 1;
  padding: 20px;
  background-color: white;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  border-radius: 10px; /* Curved borders for the dashboard content */
}

.dashboard-home h1 {
  font-size: 2rem;
  margin-bottom: 20px;
}

.dashboard-home p {
  font-size: 1.2rem;
}
/* Add this to your DashboardLayout.css */
.password-input-container {
  display: flex;
  align-items: center;
}

.password-input-container input {
  padding: 8px;
  font-size: 16px;
  width: 100%;
}

.eye-icon {
  background: none;
  border: none;
  cursor: pointer;
  padding: 5px;
  margin-left: -30px; /* Adjust this value to position the icon */
}
