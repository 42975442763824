
.login-container2 {
  display: flex;
  justify-content: center;
  align-items: center;
  background: linear-gradient(to right, rgb(255, 97, 93), hsl(78, 51%, 58%));
  /* background: linear-gradient(to right, rgb(174, 42, 42), hsl(78, 51%, 58%)); */
  height: 100vh; /* Full viewport height */
}

.login-content {
  display: flex;
  width: 90%;
  max-width: 1600px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  /* overflow: hidden; */
  background: #fff;
  margin-top: 200px;
}

.login-image,
.login-form {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
}

.login-image img {
  width: 100%;
  max-width: 700px;
}

.form-card {
  width: 100%;
  max-width: 700px;
}


h1 {
  color: #333;
  text-align: center;
}

.form-group {
  margin-bottom: 20px;
}

.login-button {
  width: 100%;
  padding: 12px;
  background-color: #8ac429;
  color: #fff;
  border: none;
  border-radius: 5px;
  font-size: 1.2em;
  cursor: pointer;
}

.error-message {
  color: red;
  text-align: center;
  margin-top: 10px;
}
.password-container {
  display: flex;
  align-items: center;
}

.password-container input {
  flex: 1;
}

.password-toggle {
  cursor: pointer;
  margin-left: 10px;
  display: flex;
  align-items: center;
}

.eye-icon {
  font-size: 1.2em;
  color: #888;
}

.eye-icon:hover {
  color: #555;
}


@media (max-width: 768px) {
  .login-content {
    flex-direction: column;
    height: auto; /* Allow flexibility for smaller screens */
  }

  .login-image {
    display: none; /* Hide image on smaller screens */
  }

  .form-card {
    max-width: 90%; /* Ensure responsive width */
  }
}
