/* Container Styles */
.teacher-details-container {
    padding: 20px;
    max-width: 800px;
    margin: 0 auto;
}

/* Back Button */
.back-button {
    background-color: #ff615d;
    color: white;
    border: none;
    padding: 8px 12px;
    border-radius: 4px;
    cursor: pointer;
    display: flex;
    align-items: center;
    margin-bottom: 20px;
}

.back-button:hover {
    background-color:#8ac429 ;
}

/* Personal Details */
.personal-details {
    display: flex;
    align-items: center;
    margin-bottom: 30px;
}

.teacher-photo {
    width: 150px;
    height: 150px;
    border-radius: 50%;
    object-fit: cover;
    margin-right: 20px;
}

.details h2 {
    margin: 0 0 10px 0;
}

.details p {
    margin: 5px 0;
}

/* Assigned Grades Section */
.assigned-grades, .class-teacher-assignments, .teacher-subjects {
    margin-bottom: 30px;
}

.assigned-grades ul, .class-teacher-assignments ul, .teacher-subjects ul {
    list-style-type: none;
    padding: 0;
}

.grade-item, .assignment-item, .subject-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    border-bottom: 1px solid #ccc;
}

.grade-item:last-child, .assignment-item:last-child, .subject-item:last-child {
    border-bottom: none;
}

/* Subject Details */
.subject-details, .assignment-details {
    flex: 1;
}

.subject-details strong, .assignment-details strong {
    display: inline-block;
    width: 80px;
}

/* Delete Icon */
.delete-icon {
    color: red;
    cursor: pointer;
    margin-left: 20px;
    flex-shrink: 0;
}

.delete-icon:hover {
    color: darkred;
}

/* Error and Success Messages */
.error-message {
    color: red;
    margin-top: 10px;
}

.success-message {
    color: green;
    margin-top: 10px;
}
