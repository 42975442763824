/* Container styling */
.change-password-container {
    max-width: 600px;
    margin: 0 auto;
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 8px;
    background-color: #f9f9f9;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    margin-top: 100px;
  }
  
  /* Heading */
  .change-password-container h2 {
    text-align: center;
    margin-bottom: 20px;
    color: #333;
    font-weight: bold;
  }
  
  /* Form fields */
  .change-password-container form div {
    margin-bottom: 15px;
  }
  
  .change-password-container label {
    display: block;
    margin-bottom: 5px;
    font-weight: 500;
    color: #555;
  }
  
  .change-password-container input[type="email"],
  .change-password-container input[type="password"],
  .change-password-container input[type="text"] {
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 14px;
  }
  
  /* Password input container styling */
  .password-input-container {
    display: flex;
    align-items: center;
    position: relative;
  }
  
  .eye-icon {
    background: none;
    border: none;
    position: absolute;
    right: 10px;
    cursor: pointer;
    font-size: 16px;
    color: #666;
  }
  
  .eye-icon:hover {
    color: #333;
  }
  
  /* Button styling */
  .change-password-container button[type="submit"] {
    width: 100%;
    padding: 12px;
    border: none;
    border-radius: 4px;
    background-color: #007bff;
    color: white;
    font-size: 16px;
    cursor: pointer;
  }
  
  .change-password-container button[type="submit"]:hover {
    background-color: #0056b3;
  }
  
  /* Error message */
  .change-password-container p {
    color: red;
    text-align: center;
    margin-top: 10px;
  }
  