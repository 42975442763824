.content-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin: 20px auto;
    max-width: 800px;
    padding: 20px;
    border: 1px solid #ddd;
    border-radius: 8px;
    background-color: #f9f9f9;
    text-align: center;
}

.header {
    font-size: 1.8em;
    color: #333;
    margin-bottom: 20px;
}

.form-container {
    display: flex;
    flex-direction: column;
    gap: 1.5em;
    width: 100%;
   
}



.field {
    display: flex;
    flex-direction: column;
    gap: 0.5em;
}

label {
    font-size: 1.1em;
    color: #555;
}

input[type="number"] {
    padding: 10px;
    font-size: 1em;
    border: 1px solid #ccc;
    border-radius: 4px;
}

.submit-button {
    padding: 10px 20px;
    font-size: 1.1em;
    background-color: #28a745;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.submit-button:disabled {
    background-color: #ddd;
    cursor: not-allowed;
}

.message {
    font-size: 1em;
    color: #28a745;
    text-align: center;
    margin-top: 20px;
}
