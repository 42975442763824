/* Styles for the form container */
.form-container5 {
    max-width: 1200px; /* Adjust the width as needed */
    margin: 0 auto;
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 8px;
    background-color: #f9f9f9;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  
  button {
    display: inline-block;
    padding: 10px 20px;
    font-size: 16px;
    color: #fff;
  
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  .button-container6 {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 15px; /* Add space between buttons */
  }

  /* For responsive design */
  @media (max-width: 600px) {
    .form-container5 {
      width: 100%;
      padding: 10px;
    }
  
    button {
      width: 100%;
    }
  }
  